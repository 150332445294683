import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { 
  EllipsisVerticalIcon, 
  MagnifyingGlassIcon,
  CheckCircleIcon,
  Square2StackIcon,
  ShareIcon
} from "@heroicons/react/24/outline";
import { CheckCircleIcon as CheckCircleSolidIcon } from "@heroicons/react/24/solid";
import DeleteAlbumButton from "./dashboard/DeleteAlbumButton";
import BulkDraftActions from "./BulkDraftActions";
import storage from "../../services/storage";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { CopyToClipboard } from "react-copy-to-clipboard";

const DraftListView = ({ drafts }) => {
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState(null);
  const [isRenaming, setIsRenaming] = useState(false);
  const [newName, setNewName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedDrafts, setSelectedDrafts] = useState([]);
  const [isCopied, setIsCopied] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveMenu(null);
        setIsRenaming(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleRename = async (draft) => {
    if (newName.trim() && newName !== draft.name) {
      try {
        await storage.setAlbumName(newName.trim(), draft);
        setIsRenaming(false);
        setActiveMenu(null);
      } catch (error) {
        console.error('Error renaming album:', error);
      }
    }
  };

  const startRenaming = (draft) => {
    setIsRenaming(true);
    setNewName(draft.name);
  };

  const toggleDraftSelection = (draft) => {
    setSelectedDrafts(prev => {
      const isSelected = prev.some(d => d.docId === draft.docId);
      return isSelected ? prev.filter(d => d.docId !== draft.docId) : [...prev, draft];
    });
  };

  const handleSelectAll = () => {
    if (selectedDrafts.length === filteredDrafts.length) {
      setSelectedDrafts([]);
    } else {
      setSelectedDrafts(filteredDrafts);
    }
  };

  const cancelSelection = () => {
    setIsSelecting(false);
    setSelectedDrafts([]);
  };

  const handleBulkDelete = () => {
    // Handle bulk delete logic here if needed
    cancelSelection();
  };

  const generateShareableLink = async (draft) => {
    const baseUrl = window.location.origin;
    const shareableUrl = `${baseUrl}/shared/draft/${draft.docId}`;
    
    try {
      const user = firebase.auth().currentUser;
      if (!user) {
        console.error('User not authenticated');
        return;
      }

      // Create a new document in the user's drafts collection with sharing metadata
      const sharedRef = firebase.firestore()
        .collection('studio')
        .doc(user.uid)
        .collection('drafts')
        .doc(draft.docId);
      
      await sharedRef.update({
        isShared: true,
        sharedAt: firebase.firestore.FieldValue.serverTimestamp(),
        sharedBy: user.uid
      });

      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (error) {
      console.error('Error generating shareable link:', error);
    }
  };

  const filteredDrafts = drafts.filter(draft => 
    draft.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const draftsList = filteredDrafts.map((draft) => {
    const { docId, name } = draft;
    const isSelected = selectedDrafts.some(d => d.docId === docId);

    return (
      <li
        key={docId}
        className="flex items-center justify-between p-4 border-t-2 border-black dark:border-white hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors duration-200 ease-in-out"
      >
        {isSelecting && (
          <button className="p-2" onClick={() => toggleDraftSelection(draft)}>
            {isSelected ? (
              <CheckCircleSolidIcon className="h-6 w-6 text-green-400" />
            ) : (
              <CheckCircleIcon className="h-6 w-6 text-gray-400" />
            )}
          </button>
        )}

        <button
          className="flex-grow py-2 px-4 text-left font-mono text-black dark:text-white font-bold tracking-widest focus:outline-none"
          onClick={() => {
            if (isSelecting) {
              toggleDraftSelection(draft);
            } else {
              navigate("/create/album", { state: { album: draft } });
            }
          }}
        >
          <span className="text-lg">{name}</span>
        </button>

        {!isSelecting && (
          <div className="relative" ref={menuRef}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setActiveMenu(activeMenu === docId ? null : docId);
                setIsRenaming(false);
              }}
              className="p-2 text-gray-500 dark:text-gray-300 hover:text-gray-700 dark:hover:text-white transition-colors"
            >
              <EllipsisVerticalIcon className="h-6 w-6" />
            </button>

            {activeMenu === docId && (
              // Use onMouseDown here to prevent closing, but not interfere with focusing the input
              <div 
                className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-xl z-10 py-1 border border-gray-200 dark:border-gray-700"
                onMouseDown={(e) => e.stopPropagation()}
              >
                {isRenaming ? (
                  <form 
                    className="p-2"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleRename(draft);
                    }}
                  >
                    <input
                      type="text"
                      value={newName}
                      onChange={(e) => setNewName(e.target.value)}
                      className="w-full px-2 py-1 bg-gray-100 dark:bg-gray-700 text-black dark:text-green-400 border border-gray-300 dark:border-gray-600 rounded focus:outline-none focus:border-green-400"
                      autoFocus
                      onKeyDown={(e) => {
                        if (e.key === 'Escape') {
                          setIsRenaming(false);
                          setActiveMenu(null);
                          setNewName('');
                        }
                      }}
                    />
                  </form>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        startRenaming(draft);
                        setNewName(draft.name);
                      }}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      Rename
                    </button>
                    <CopyToClipboard 
                      text={`${window.location.origin}/shared/draft/${draft.docId}`}
                      onCopy={() => generateShareableLink(draft)}
                    >
                      <button
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 border-t border-gray-200 dark:border-gray-700"
                      >
                        <div className="flex items-center">
                          <ShareIcon className="h-4 w-4 mr-2" />
                          {isCopied ? 'Link Copied!' : 'Share Draft'}
                        </div>
                      </button>
                    </CopyToClipboard>
                    <div className="border-t border-gray-200 dark:border-gray-700">
                      <DeleteAlbumButton album={draft} />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </li>
    );
  });

  return (
    <div className="my-8">
      <div className="mb-4 flex items-center space-x-4">
        <div className="relative flex-grow">
          <input
            type="text"
            placeholder="Search drafts..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 bg-gray-100 dark:bg-black border-2 border-black dark:border-white rounded-none text-black dark:text-white placeholder-gray-500 focus:outline-none"
          />
          <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-500 dark:text-gray-400" />
        </div>
        {!isSelecting && filteredDrafts.length > 0 && (
          <button
            onClick={() => setIsSelecting(true)}
            className="flex items-center space-x-2 px-4 py-2 bg-gray-100 dark:bg-black border-2 border-black dark:border-white text-black dark:text-white hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors"
          >
            <Square2StackIcon className="h-5 w-5" />
            <span>Select</span>
          </button>
        )}
        {isSelecting && (
          <button
            onClick={handleSelectAll}
            className="px-4 py-2 bg-gray-100 dark:bg-black border-2 border-black dark:border-white text-black dark:text-white hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors"
          >
            {selectedDrafts.length === filteredDrafts.length ? 'Deselect All' : 'Select All'}
          </button>
        )}
      </div>

      <ul className="bg-gray-100 dark:bg-black border-2 border-black dark:border-white shadow-lg rounded-none">
        {filteredDrafts.length > 0 ? (
          draftsList
        ) : (
          <li className="px-6 py-4 text-gray-500 dark:text-gray-400 text-center">
            {drafts.length === 0 ? (
              "No drafts yet. Create one to get started!"
            ) : (
              "No drafts match your search."
            )}
          </li>
        )}
      </ul>

      {isSelecting && (
        <BulkDraftActions
          selectedDrafts={selectedDrafts}
          onDelete={handleBulkDelete}
          onCancel={cancelSelection}
        />
      )}
    </div>
  );
};

export default DraftListView;