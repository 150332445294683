import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useAuthState } from 'react-firebase-hooks/auth';

const db = firebase.firestore();

const useAlbumInfo = (album) => {
    const [albumInfo, setAlbumInfo] = useState(album || null);
    const [artistId, setArtistId] = useState(album?.artist_id || null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [user] = useAuthState(firebase.auth());

    useEffect(() => {
        // If no album is provided, reset states
        if (!album) {
            setAlbumInfo(null);
            setArtistId(null);
            setError(null);
            return;
        }

        // If we have an artist_id in the album object, use it
        if (album.artist_id) {
            setArtistId(album.artist_id);
        }

        if (!user || !user.uid) {
            setError("User not authenticated");
            return;
        }

        if (!album?.docId) {
            // If no docId, just use the album object as is
            setAlbumInfo(album);
            return;
        }

        setLoading(true);

        const albumRef = db.collection("studio").doc(user.uid).collection("drafts").doc(album.docId);

        const unsubscribe = albumRef.onSnapshot(doc => {
            if (doc.exists) {
                const data = doc.data();
                const mergedData = {
                    ...album,           // Keep original album data
                    ...data,            // Override with Firestore data
                    docId: album.docId  // Ensure docId is preserved
                };
                setAlbumInfo(mergedData);
                setArtistId(data.artist_id || album.artist_id);
                setError(null);
            } else {
                console.log("Album document not found, using passed album data");
                setAlbumInfo(album);
                setArtistId(album.artist_id);
            }
            setLoading(false);
        }, err => {
            console.error("Error fetching album:", err);
            setError(err.message);
            setAlbumInfo(album);
            setArtistId(album.artist_id);
            setLoading(false);
        });

        return () => unsubscribe();
    }, [user, album]);

    return { albumInfo, artistId, loading, error };
};

export default useAlbumInfo;