import { useMemo } from "react";
import APIConfig from "../../../../APIConfig";
import { useFetchWithAuth } from "../../../../data layer/useFetchWithAuth";

const useProfileInfo = () => {
  const profileUrl = `${APIConfig.me()}`;
  const {
    data: profileInfo,
    loading,
    error,
  } = useFetchWithAuth(profileUrl, {
    method: "GET",
  });

  const artists = useMemo(() => {
    if (!profileInfo || !profileInfo.artists) {
      return [];
    }
    return profileInfo.artists;
  }, [profileInfo?.artists]);

  return { profileInfo, artists, loading, error };
};

export default useProfileInfo;
