import React from "react";
import useFetchArtistData from "../../../../events/hooks/useFetchArtistData";
import AlbumList from "./AlbumList";
import DynamicRotatingSlash from "../../../../loading/DynamicRotatingSlash";

const ArtistDetailsView = ({ artistId }) => {
  const { artistData, loading, error } = useFetchArtistData(artistId);

  if (loading) {
    return (
      <div className="p-2 text-gray-400">
        <DynamicRotatingSlash />
      </div>
    );
  }

  if (error) {
    return <div className="p-2 text-red-400">Error: {error.message}</div>;
  }

  if (!artistData) {
    return <div className="p-2 text-gray-400">No artist data found</div>;
  }

  return (
    <div className="p-2 text-gray-200">
      <AlbumList albums={artistData.albums} />
    </div>
  );
};

export default ArtistDetailsView;
