import React, { useState, memo, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PencilIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import TrackCellArtistView from "./TrackCellArtistView";
import ArtistSearch from "../../search/ArtistSearch";
import useArtistChange from "./album_creator/hooks/useArtistChange";
import useProfileInfo from "./hooks/useProfileInfo";

const TrackArtistEditor = ({ track, album }) => {
  const [editMode, setEditMode] = useState(false);
  const [searchMode, setSearchMode] = useState(false);
  const { artists, loading: artistsLoading } = useProfileInfo();
  const { handleArtistChange } = useArtistChange(track, album);

  const currentArtistId = track?.metadata?.artist_id || null;
  const currentArtist = useMemo(() => {
    if (!currentArtistId) return null;
    return artists?.find((a) => a.id === currentArtistId) || null;
  }, [artists, currentArtistId]);

  const handleClose = () => {
    setEditMode(false);
    setSearchMode(false);
  };

  return (
    <div className="cursor-pointer">
      {editMode ? (
        <div className="flex flex-col space-y-4 py-5 mb-5 bg-gray-800 rounded-lg p-4 relative border border-gray-700">
          {/* Close Button */}
          <button
            onClick={handleClose}
            className="absolute right-0 top-0 p-2 text-gray-500 hover:text-gray-400"
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>

          {/* Current Artist Display */}
          <div className="space-y-1">
            <span className="text-xs font-medium px-3 py-1 bg-blue-500/20 text-blue-400 rounded-full border border-blue-500/20 inline-block">
              CURRENT ARTIST
            </span>
            <div className="text-gray-300 font-medium">
              {currentArtist ? currentArtist.name : "No Artist (Compilation)"}
            </div>
          </div>

          {/* Mode Toggle */}
          <div className="flex space-x-2 text-sm pt-2">
            <button
              onClick={() => setSearchMode(false)}
              className={`px-3 py-1 border rounded-md ${
                !searchMode
                  ? "border-blue-500/20 bg-blue-500/20 text-blue-400"
                  : "border-gray-700 bg-gray-900 text-gray-400 hover:text-gray-300"
              }`}
            >
              Linked Artists
            </button>
            <button
              onClick={() => setSearchMode(true)}
              className={`px-3 py-1 border rounded-md ${
                searchMode
                  ? "border-blue-500/20 bg-blue-500/20 text-blue-400"
                  : "border-gray-700 bg-gray-900 text-gray-400 hover:text-gray-300"
              }`}
            >
              Search All
            </button>
          </div>

          <div className="flex-grow pr-2 relative text-sm">
            {searchMode ? (
              <ArtistSearch
                onSelect={(artist) => {
                  handleArtistChange(artist);
                  handleClose();
                }}
                renderResult={(artist) => (
                  <li className="py-2 px-3 hover:bg-gray-700/50 text-gray-200 cursor-pointer rounded-md flex items-center space-x-2">
                    <span>{artist.name}</span>
                  </li>
                )}
                placeholder="Search and select artist..."
                styleVariant="compact"
              />
            ) : (
              <div className="space-y-2">
                {artistsLoading ? (
                  <div className="text-gray-400">Loading artists...</div>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        handleArtistChange({ id: null, name: "" });
                        handleClose();
                      }}
                      className={`w-full text-left px-3 py-2 hover:bg-gray-700/50 text-gray-200 rounded-md flex items-center space-x-2 ${
                        currentArtistId === null ? "bg-gray-700/30" : ""
                      }`}
                    >
                      {currentArtistId === null && (
                        <CheckCircleIcon className="h-5 w-5 text-green-400" />
                      )}
                      <span>No Artist (Compilation)</span>
                    </button>

                    {artists.map((artist) => {
                      const isSelected = artist.id === currentArtistId;
                      return (
                        <button
                          key={artist.id}
                          onClick={() => {
                            handleArtistChange(artist);
                            handleClose();
                          }}
                          className={`w-full text-left px-3 py-2 hover:bg-gray-700/50 text-gray-200 rounded-md flex items-center space-x-2 ${
                            isSelected ? "bg-gray-700/30" : ""
                          }`}
                        >
                          {isSelected && (
                            <CheckCircleIcon className="h-5 w-5 text-green-400" />
                          )}
                          <span>{artist.name}</span>
                        </button>
                      );
                    })}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          onClick={() => setEditMode(true)}
          className="flex items-center space-x-2 bg-gray-800 hover:bg-gray-700/50 rounded-md px-2 py-1 transition-colors duration-200"
        >
          <TrackCellArtistView artistId={currentArtistId} />
          <PencilIcon className="h-4 w-4 text-gray-500 hover:text-gray-400" />
        </div>
      )}
    </div>
  );
};

export default memo(TrackArtistEditor);