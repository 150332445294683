// FinalizeDraftV2.jsx

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import api from "../../../api";
import storage from "../../../services/storage";
import StandardButton from "../../buttons/StandardButton";
import WideLinkButton from "../../buttons/WideLinkButton";
import WideButton from "../../buttons/WideButton";
import DynamicRotatingSlash from "../../loading/DynamicRotatingSlash";
const FinalizeDraft = () => {
  const [isLoading, setLoading] = useState(true);
  const [album, setAlbum] = useState({});
  const [published, setPublished] = useState(false);
  let location = useLocation();

  const getTracks = () => {
    return (
      <ol className="list-decimal list-inside">
        {album.tracks
          .slice(0)
          .reverse()
          .map((track, index) => (
            <li key={track.name} className="text-lg py-1">
              {track.name}
            </li>
          ))}
      </ol>
    );
  };

  useEffect(() => {
    const loadAlbum = async () => {
      try {
        setLoading(true);
        let albumToValidate;

        if (location.state?.album) {
          // Use the album from location state
          albumToValidate = location.state.album;
        } else {
          // Fallback to loading the most recent draft
          albumToValidate = await storage.getRecentDrafts();
          if (!albumToValidate?.docId) {
            console.error("No recent drafts found or draft ID is missing");
            setLoading(false);
            return;
          }
        }

        // Validate the album
        try {
          const validatedAlbum = await storage.validateDraft(albumToValidate);
          setAlbum(validatedAlbum);
        } catch (error) {
          console.error("Error validating album:", error);
          // Keep the error message visible by not setting loading to false
          return;
        }
      } catch (error) {
        console.error("Error loading album:", error);
      }
      
      setLoading(false);
    };

    loadAlbum();
  }, [location.state]);

  function publish() {
    api.newAlbum(album.docId).then((res) => {
      console.log("res", res);
    });

    setPublished(true);
  }
  const albumView = () => (
    <div className="container mx-auto my-10 max-w-4xl">
      {isLoading ? (
        <div className="flex justify-center items-center min-h-[400px]">
          <DynamicRotatingSlash />
        </div>
      ) : (
        <div className="bg-zinc-900 rounded-xl p-8 shadow-xl">
          <div className="md:grid md:grid-cols-2 gap-8">
            {/* Left column - Album Cover */}
            <div className="space-y-4">
              <div className="relative group">
                <img
                  src={album.image_url}
                  alt="Album Cover"
                  className="w-full aspect-square object-cover rounded-lg shadow-2xl transition-transform duration-200 group-hover:scale-[1.02]"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-200 rounded-lg" />
              </div>
              <h1 className="text-3xl font-bold text-white mt-4">{album.name}</h1>
            </div>

            {/* Right column - Track List and Actions */}
            <div className="mt-8 md:mt-0 space-y-6">
              <div>
                <h2 className="text-xl font-semibold text-white mb-4">Track List</h2>
                <div className="bg-zinc-800/50 rounded-lg p-4">
                  <ol className="list-decimal list-inside space-y-2">
                    {album.tracks
                      .slice(0)
                      .reverse()
                      .map((track, index) => (
                        <li key={track.name} className="text-lg text-gray-200 py-1 px-2 rounded hover:bg-zinc-700/50 transition-colors">
                          {track.name}
                        </li>
                      ))}
                  </ol>
                </div>
              </div>
              
              <div className="pt-4">
                <button
                  onClick={() => publish()}
                  disabled={published}
                  className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-4 px-6 rounded-lg transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {published ? "Published!" : "Publish Album"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const successView = () => (
    <div className="text-center">
      <p className="text-lg mb-3">
        Success! Your album will be available shortly.
      </p>
      <WideLinkButton path="/konsole" text="back to konsole"></WideLinkButton>
    </div>
  );
  return (
    <div className="min-h-screen flex items-center justify-center ">
      {published ? successView() : albumView()}
    </div>
  );
};

export default FinalizeDraft;
