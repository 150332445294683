// useSetAlbumArtist.js
import { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useAuthState } from 'react-firebase-hooks/auth';

const db = firebase.firestore();

export const useSetAlbumArtist = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user] = useAuthState(firebase.auth());

  const setAlbumArtist = async (albumId, artistId) => {
    if (!user) {
      setError("No user logged in");
      return;
    }

    if (!albumId) {
      setError("No album ID provided");
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const albumRef = db
        .collection("studio")
        .doc(user.uid)
        .collection("drafts")
        .doc(albumId);

      // Update both artist_id and metadata.artist_id for consistency
      await albumRef.update({ 
        artist_id: artistId,
        "metadata.artist_id": artistId
      });

      console.log(`Album artist updated successfully. Album: ${albumId}, Artist: ${artistId}`);
    } catch (err) {
      console.error("Error setting album artist:", err);
      setError(err.message);
      throw err; // Propagate error to caller
    } finally {
      setLoading(false);
    }
  };

  return { setAlbumArtist, loading, error };
};
