import React from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import storage from '../../services/storage';

const BulkDraftActions = ({ selectedDrafts, onDelete, onCancel }) => {
  const handleDelete = async () => {
    if (window.confirm(`Are you sure you want to delete ${selectedDrafts.length} draft${selectedDrafts.length > 1 ? 's' : ''}?`)) {
      try {
        await Promise.all(selectedDrafts.map(draft => storage.deleteAlbum(draft)));
        onDelete();
      } catch (error) {
        console.error('Error deleting drafts:', error);
      }
    }
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-100 dark:bg-black border-t-2 border-black dark:border-white p-4 flex items-center justify-between">
      <div className="text-black dark:text-white">
        {selectedDrafts.length} draft{selectedDrafts.length > 1 ? 's' : ''} selected
      </div>
      <div className="flex space-x-4">
        <button
          onClick={onCancel}
          className="px-4 py-2 text-black dark:text-white hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors"
        >
          Cancel
        </button>
        <button
          onClick={handleDelete}
          className="flex items-center space-x-2 px-4 py-2 bg-red-600 text-white hover:bg-red-700 transition-colors"
        >
          <TrashIcon className="h-5 w-5" />
          <span>Delete Selected</span>
        </button>
      </div>
    </div>
  );
};

export default BulkDraftActions;
