import React from "react";
import AlbumDraftUploadButton from "../drafting/AlbumDraftUploadButton";
import TrackFileEditor from "./TrackFileEditor";
import { useState } from "react";
// Correct import statement
import { PencilIcon, TrashIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import DeleteFileButton from "../../file_mgmt/DeleteFileButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import TrackArtistEditor from "./TrackArtistCreator";

// import ReactTooltip from 'react-tooltip';
// import ReactTooltipDefaultExport, { TooltipProps } from 'react-tooltip';
const TaskActions = ({ onRename, onDelete, uploadComplete, track, album, isFileCell }) => {
    const [showFileEditor, setShowFileEditor] = useState(false);

    return (
        <div className="flex items-center space-x-4">
            {!isFileCell && (
                <>
                    <button
                        onClick={onRename}
                        className="text-blue-400 hover:text-blue-500 text-sm font-theFutureMonoRegular"
                    >
                        rename
                    </button>
                    
                    <TrackFileEditor
                        track={track}
                        album={album}
                    />

                    <button
                        onClick={onDelete}
                        className="text-gray-500 hover:text-red-500 transition-colors duration-200"
                    >
                        <TrashIcon className="h-5 w-5" />
                    </button>
                </>
            )}
            {isFileCell && (
                <DeleteFileButton track={track} onDeleteComplete={onDelete} />

            )}
        </div>
    );
};

export default TaskActions;
