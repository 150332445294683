import React, { useEffect, useState } from "react";
import useAudioStorage from "../../file_mgmt/useAudioStorage";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

const TrackFileEditor = ({ track, album, onReplaceComplete }) => {
  const { uploadAndReplaceAudioForTrack, progress, setProgress } = useAudioStorage();
  const [uploadComplete, setUploadComplete] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);

  const fileInputId = `file-input-${track.id}`;

  useEffect(() => {
    let timeout;
    if (progress === 100) {
      setUploadComplete(true);
      setIsUploading(false);
      onReplaceComplete && onReplaceComplete();
      timeout = setTimeout(() => {
        setUploadComplete(false);
        setProgress(0);
      }, 3000);
    }
    return () => clearTimeout(timeout);
  }, [progress, onReplaceComplete, setProgress]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsUploading(true);
    setError(null);

    try {
      await uploadAndReplaceAudioForTrack(file, track, album);
    } catch (err) {
      console.error("Error replacing file:", err);
      setError(err.message || "Failed to upload file");
      setIsUploading(false);
      setProgress(0);
    }
  };

  return (
    <div className="relative">
      {/* Hidden file input */}
      <input
        id={fileInputId}
        type="file"
        accept="audio/*"
        onChange={handleFileChange}
        className="sr-only"
      />

      {/* Clickable label to open file dialog */}
      <label
        htmlFor={fileInputId}
        className={`inline-flex items-center space-x-2 px-3 py-1 rounded-md text-sm ${
          isUploading
            ? "bg-gray-700 cursor-not-allowed"
            : "bg-blue-500/20 hover:bg-blue-500/30 cursor-pointer"
        } text-blue-400 transition-colors duration-200`}
      >
        {isUploading ? (
          <>
            <div className="h-4 w-4 border-2 border-blue-400 border-t-transparent rounded-full animate-spin"></div>
            <span>Uploading... {Math.round(progress)}%</span>
          </>
        ) : uploadComplete ? (
          <>
            <CheckCircleIcon className="h-4 w-4 text-green-400" />
            <span>Upload Complete!</span>
          </>
        ) : (
          <>
            <ArrowUpTrayIcon className="h-4 w-4" />
            <span>Replace File</span>
          </>
        )}
      </label>

      {isUploading && (
        <div className="mt-2 w-full h-1 bg-gray-700 rounded-full overflow-hidden">
          <div
            className="h-full bg-blue-500 transition-all duration-200"
            style={{ width: `${progress}%` }}
          />
        </div>
      )}

      {error && (
        <div className="mt-2 text-sm text-red-500">
          {error}
        </div>
      )}
    </div>
  );
};

export default TrackFileEditor;