import React, { useContext } from 'react';
import AudioPlayerContext from '../../../../file_mgmt/drive/AudioPlayerContext';

const TrackList = ({ tracks }) => {
  const { playAudio } = useContext(AudioPlayerContext);

  const handleTrackClick = (track) => {
    console.log("track", track)
    const audioSrc = {
      title: track.name,
      artist: track.artist,
      audioSrc: track.compressed_file,
    };
    console.log("audioSrc", audioSrc)
    playAudio(audioSrc);
  };

  return (
    <div className="pl-4 space-y-2">
      {tracks.map((track, index) => (
        <div 
          key={track.id} 
          onClick={() => handleTrackClick(track)} 
          className="flex items-center cursor-pointer hover:bg-gray-700 p-2 rounded-md transition-colors duration-200 group"
        >
          <span className="mr-2 text-gray-400 group-hover:text-gray-200">{index + 1}.</span>
          <div className="text-gray-200 group-hover:text-white">{track.name}</div>
        </div>
      ))}
    </div>
  );
};

export default TrackList;
