// useUserProfile.js

import { useState, useEffect } from "react";
import { useFetchWithAuth } from "../data layer/useFetchWithAuth";
import APIConfig from "../APIConfig";

const useUserProfile = () => {
  const [artistName, setArtistName] = useState("");
  const [isArtist, setArtistStatus] = useState(false);
  const [hasStudio, setStudioStatus] = useState(false);
  const [artistLink, setArtistLink] = useState("");
  const [stripeDashLink, setStripeDashLink] = useState(false);
  const [isSeller, setSellerStatus] = useState(false);
  const [username, setUsername] = useState("");

  const { data, loading, error } = useFetchWithAuth(`${APIConfig.profiles()}me/`);

  useEffect(() => {
    if (data) {
      if (data.artists && data.artists.length > 0) {
        setArtistName(data.artists[0].name);
        setArtistStatus(true);
      } else {
        setArtistName("no artist");
        setArtistStatus(false);
      }

      setUsername(data.username || "user");
      setStudioStatus(data.has_studio || false);
      setSellerStatus(data.is_seller || false);
      setArtistLink(data.artist_profile_link || "");

      if (data.is_seller && data.seller_info) {
        setStripeDashLink(data.seller_info.stripe_acct_login_link || "");
        localStorage.setItem("seller_info", data.seller_info.seller_acct_id);
      }
    }
  }, [data]);

  return {
    artistName,
    username,
    isArtist,
    hasStudio,
    artistLink,
    stripeDashLink,
    isSeller,
    loading,
    error,
  };
};

export default useUserProfile;
