// ArtistListElement.jsx
import React, { useState } from 'react';
import ArtistDetailsView from './ArtistDetailsView';

const ArtistListElement = ({ artistId, artistName }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <li className="pl-4 py-2">
      <div className="flex items-center group hover:bg-gray-700 p-2 rounded-md transition-colors duration-200">
        <button 
          className="mr-2 w-6 h-6 flex items-center justify-center rounded hover:bg-gray-600 text-gray-400 hover:text-gray-200 transition-colors duration-200" 
          onClick={toggleExpand}
        >
          {isExpanded ? '−' : '+'}
        </button>
        <span className="text-gray-200 group-hover:text-white">{artistName}</span>
      </div>
      {isExpanded && (
        <div className="mt-2 ml-4 border-l-2 border-gray-700">
          <ArtistDetailsView artistId={artistId} />
        </div>
      )}
    </li>
  );
};

export default ArtistListElement;
