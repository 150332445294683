import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import storage from "../../../../services/storage";
import drafting from "../../../../services/drafting";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const useAlbumLoader = (initialAlbum, location) => {
  const [album, setAlbum] = useState(initialAlbum);
  const [isLoading, setLoading] = useState(true);
  const [tracks, setTracks] = useState([]);
  const [artistId, setArtistId] = useState(0);
  const navigate = useNavigate();

  const finalize = useCallback(async () => {
    const _album = await storage.validateDraft(album);
    if (_album.isValid) {
      navigate("/publish/album", { state: { album: _album } });
    } else {
      alert("Finish creating the album first");
    }
  }, [album, navigate]);

  const loadDraft = useCallback(async (docId) => {
    if (!docId) {  // Check for a valid docId
      console.error("Error: docId is not provided.");
      return;
    }
    
    try {
      const album_draft = await drafting.getDraft(docId);
      setArtistId(album_draft.artist_id);
      const tracksArray = Object.values(album_draft.tracks).map((song) => ({
        title: song.track_name,
        artist: album_draft.artist,
        audioSrc: song.url,
      }));
      setTracks(tracksArray);
      setAlbum(album_draft);
    } catch (error) {
      console.error("Error loading draft:", error);
    }
  }, []);

  // Subscribe to album document changes
  useEffect(() => {
    if (!album?.docId) return;

    const user = firebase.auth().currentUser;
    if (!user) {
      console.error('No user logged in.');
      return;
    }

    const albumRef = firebase.firestore()
      .collection('studio')
      .doc(user.uid)
      .collection('drafts')
      .doc(album.docId);

    const unsubscribe = albumRef.onSnapshot(snapshot => {
      if (snapshot.exists) {
        const data = snapshot.data();
        setAlbum(prevAlbum => ({
          ...prevAlbum,
          ...data
        }));
      }
    }, error => {
      console.error("Error subscribing to album changes:", error);
    });

    return () => unsubscribe();
  }, [album?.docId]);

  const loadAlbum = useCallback(async () => {
    setLoading(true);  // Ensure loading state is updated
    
    try {
      if (location.state && location.state.album) {
        // Use the album from location state
        setAlbum(location.state.album);
        await loadDraft(location.state.album.docId);
      } else {
        // Fallback to loading the most recent draft if no album is passed
        const draft = await storage.getRecentDrafts();
        if (draft && draft.docId) {
          await loadDraft(draft.docId);
        } else {
          console.error("No recent drafts found or draft ID is missing");
        }
      }
    } catch (error) {
      console.error("Error loading album:", error);
    }

    setLoading(false);
  }, [location.state, loadDraft]);

  return { album, isLoading, tracks, artistId, finalize, loadAlbum };
};

export default useAlbumLoader;