import React from 'react';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';

const AlbumProgressBar = ({ validationState, validationMessage }) => {
  if (!validationState) {
    return null;
  }

  const steps = [
    {
      label: 'Album Name',
      isComplete: validationState.hasAlbumName,
      errorMessage: !validationState.hasAlbumName ? 'Add an album name' : null,
    },
    {
      label: 'Cover Art',
      isComplete: validationState.hasCover,
      errorMessage: !validationState.hasCover ? 'Upload cover art' : null,
    },
    {
      label: 'Tracks',
      isComplete: validationState.hasTracks && validationState.hasValidTracks,
      errorMessage: !validationState.hasTracks 
        ? 'Add at least one track' 
        : !validationState.hasValidTracks 
        ? 'All tracks must have names'
        : null,
    }
  ];

  const completedSteps = steps.filter(step => step.isComplete).length;
  const progress = (completedSteps / steps.length) * 100;

  return (
    <div className="w-full space-y-3">
      <div className="flex items-center justify-between">
        <span className="text-sm text-blue-400 font-mono uppercase tracking-wider">
          ALBUM PROGRESS
        </span>
        <span className="text-sm text-blue-400">
          {Math.round(progress)}%
        </span>
      </div>

      {/* Progress Bar */}
      <div className="h-1.5 w-full bg-gray-800 rounded-full overflow-hidden">
        <div
          style={{ width: `${progress}%` }}
          className={`h-full transition-all duration-500 ease-out ${
            progress === 100 ? 'bg-green-500' : 'bg-blue-500'
          }`}
        />
      </div>

      {/* Steps List */}
      <div className="space-y-2 mt-4">
        {steps.map((step) => (
          <div 
            key={step.label}
            className={`flex items-center justify-between px-4 py-3 rounded-lg transition-colors duration-200 ${
              validationState.isLoading
                ? 'bg-gray-800/30'
                : step.isComplete
                ? 'bg-gray-800/50 border border-green-500/20'
                : 'bg-gray-800/50 border border-red-500/20'
            }`}
          >
            <div className="flex items-center space-x-3">
              {validationState.isLoading ? (
                <div className="h-5 w-5 rounded-full border-2 border-gray-600 border-t-transparent animate-spin" />
              ) : step.isComplete ? (
                <CheckCircleIcon className="h-5 w-5 text-green-400" />
              ) : (
                <ExclamationCircleIcon className="h-5 w-5 text-red-400" />
              )}
              <span className={`text-sm ${step.isComplete ? 'text-gray-200' : 'text-gray-400'}`}>
                {step.label}
              </span>
            </div>
            {!validationState.isLoading && !step.isComplete && step.errorMessage && (
              <span className="text-xs text-red-400">
                {step.errorMessage}
              </span>
            )}
          </div>
        ))}
      </div>

      {validationMessage && (
        <div className={`mt-4 text-sm ${progress === 100 ? 'text-green-400' : 'text-blue-400'}`}>
          {validationMessage}
        </div>
      )}
    </div>
  );
};

export default AlbumProgressBar;
