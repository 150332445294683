import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import CreateAlbumView from "./album_creator/CreateAlbumView";
import AlbumDraftImageUploader from "./album_creator/views/images/AlbumDraftImageUploader";
import EditAlbumMetadataView from "./EditAlbumMetadataView";
import SetAlbumArtistView from "./album_creator/views/SetAlbumArtistView";
import ModularSidebar from "../views/ModularSidebar";
import WideButton from "../../buttons/WideButton";
import LightSpinner from "../../loading/LightSpinner";
import AlbumSettingsView from "./AlbumSettingsView";
import useAlbumLoader from "./hooks/useAlbumLoader";
import useDraftValidationObserver from "./hooks/useDraftValidationObserver";
import AlbumProgressBar from "./AlbumProgressBar";

function DraftEditView() {
  const location = useLocation();
  const history = useNavigate();
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const { album, isLoading, finalize, loadAlbum } = useAlbumLoader({}, location);
  const { isValid, validationState, isLoading: isValidating, validationMessage } = useDraftValidationObserver(album);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    loadAlbum();
  }, [loadAlbum]);

  useEffect(() => {
    const checkIfLargeScreen = () => {
      setSidebarOpen(window.innerWidth >= 768);
    };

    checkIfLargeScreen();
    window.addEventListener("resize", checkIfLargeScreen);
    return () => window.removeEventListener("resize", checkIfLargeScreen);
  }, []);

  const handleFinalize = async () => {
    if (!isValid) return;
    await finalize();
  };

  const SHOW_SIDEBAR = false;

  return (
    <div className="flex w-full h-screen bg-black pb-5 overflow-hidden">
      {SHOW_SIDEBAR && (
        <ModularSidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      )}
      <div className={`flex-1 flex flex-col ${SHOW_SIDEBAR && isSidebarOpen ? "lg:ml-64" : ""}`}>
        <main className="flex-grow px-4 sm:px-6 lg:px-8 py-8 overflow-auto w-full bg-black">
          {isLoading ? (
            <div className="flex items-center justify-center p-10 w-full bg-black">
              <LightSpinner />
            </div>
          ) : (
            <div className="flex flex-col w-full space-y-8">
              {/* Album Header Card */}
              <div className="bg-[#0E1117] rounded-lg shadow-xl">
                <div className="flex flex-col md:flex-row md:items-start">
                  {/* Left Column - Album Cover */}
                  <div className="w-full md:w-1/3 p-6 md:border-r border-gray-800">
                    <AlbumDraftImageUploader album={album} />
                  </div>

                  {/* Right Column - Album Info */}
                  <div className="w-full md:w-2/3 p-6">
                    <div className="flex flex-col h-full">
                      {/* Header with Settings Button */}
                      <div className="flex justify-end mb-4">
                        <button
                          onClick={() => setIsSettingsOpen(true)}
                          className="p-2 text-gray-400 hover:text-green-400 transition-colors"
                          title="Album Settings"
                        >
                          <Cog6ToothIcon className="h-5 w-5" />
                        </button>
                      </div>

                      {/* Album Title & Artist */}
                      <div className="space-y-4 mb-8">
                        <EditAlbumMetadataView album={album} />
                        <SetAlbumArtistView album={album} />
                      </div>

                      {/* Progress Section */}
                      <div className="flex-grow">
                        <AlbumProgressBar
                          validationState={validationState}
                          validationMessage={validationMessage}
                        />
                      </div>

                      {/* Action Buttons */}
                      <div className="mt-8">
                        {/* Finalize button centered */}
                        <div className="flex justify-center">
                          {isValid && !isValidating && (
                            <button
                              onClick={handleFinalize}
                              className="group relative inline-flex items-center justify-center px-8 py-3 text-lg font-medium text-green-400 bg-transparent border-2 border-green-400 rounded-lg hover:bg-green-400/10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400 transition-all duration-200"
                            >
                              <span className="relative">Finalize Album</span>
                              <span className="absolute inset-0 transform translate-x-0.5 translate-y-0.5 bg-green-400/20 rounded-lg transition-transform group-hover:translate-x-0 group-hover:translate-y-0"></span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Track Editor Section */}
              <div className="bg-[#0E1117] rounded-lg shadow-xl p-6">
                {/* <div className="mb-4"> */}
                {/* </div> */}
                <CreateAlbumView album={album} />
              </div>

              {/* Settings Modal */}
              <AlbumSettingsView
                album={album}
                isOpen={isSettingsOpen}
                onClose={() => setIsSettingsOpen(false)}
              />
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default DraftEditView;