// useImageUpload.js
import { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import storage from "../../../../../services/storage";

const useImageUpload = (album) => {
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState(null);

  const uploadImage = async (file) => {
    if (!album?.docId) {
      console.error("No album or docId provided");
      return;
    }

    setUploadStatus("uploading");
    
    // Use a timestamp to ensure unique filenames
    const timestamp = Date.now();
    const fileExtension = file.name.split('.').pop();
    const filename = `${timestamp}.${fileExtension}`;
    
    // Use the same path structure as in storage.js
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`studio/user_storage/${firebase.auth().currentUser.uid}/drafts/${album.docId}/images/${filename}`);
    const uploadTask = fileRef.put(file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          console.log('Upload progress:', progress);
        },
        error => {
          console.error("Upload error:", error);
          setUploadStatus("failed");
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            console.log("File uploaded, URL:", downloadURL);
            
            // Use the timestamp-based filename for consistency
            await storage.updateAlbumCover(album, downloadURL, filename, file);
            console.log("Album cover updated successfully");
            
            setUploadStatus("success");
            resolve(downloadURL);
          } catch (error) {
            console.error("Error updating album cover:", error);
            setUploadStatus("failed");
            reject(error);
          }
        }
      );
    });
  };

  return { uploadImage, progress, uploadStatus };
};

export default useImageUpload;
