import React, { createContext, useContext, useState, useCallback } from 'react';

const PlayerContext = createContext(null);

export const usePlayer = () => {
  const context = useContext(PlayerContext);
  if (!context) {
    throw new Error('usePlayer must be used within a PlayerProvider');
  }
  return context;
};

export const PlayerProvider = ({ children }) => {
  const [currentTrack, setCurrentTrack] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playlist, setPlaylist] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [volume, setVolume] = useState(1);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isLossless, setIsLossless] = useState(false);

  const play = useCallback((track, tracks = [], useLossless = false) => {
    if (Array.isArray(tracks) && tracks.length > 0) {
      setPlaylist(tracks);
      const index = tracks.findIndex(t => t.id === track.id);
      setCurrentIndex(Math.max(0, index));
    }
    setCurrentTrack(track);
    setIsPlaying(true);
    setIsLossless(useLossless);
  }, []);

  const pause = useCallback(() => {
    setIsPlaying(false);
  }, []);

  const next = useCallback(() => {
    if (playlist.length > 0) {
      const nextIndex = (currentIndex + 1) % playlist.length;
      const nextTrack = playlist[nextIndex];
      setCurrentIndex(nextIndex);
      // Preserve the current quality setting when moving to next track
      play(nextTrack, playlist, isLossless);
    }
  }, [currentIndex, playlist, isLossless, play]);

  const previous = useCallback(() => {
    if (playlist.length > 0) {
      const prevIndex = currentIndex === 0 ? playlist.length - 1 : currentIndex - 1;
      const prevTrack = playlist[prevIndex];
      setCurrentIndex(prevIndex);
      // Preserve the current quality setting when moving to previous track
      play(prevTrack, playlist, isLossless);
    }
  }, [currentIndex, playlist, isLossless, play]);

  const seek = useCallback((time) => {
    setProgress(time);
  }, []);

  const updateProgress = useCallback((time) => {
    setProgress(time);
  }, []);

  const updateDuration = useCallback((time) => {
    setDuration(time);
  }, []);

  const updateVolume = useCallback((level) => {
    setVolume(Math.max(0, Math.min(1, level)));
  }, []);

  const value = {
    currentTrack,
    isPlaying,
    volume,
    progress,
    duration,
    playlist,
    isLossless,
    play,
    pause,
    next,
    previous,
    updateProgress,
    updateDuration,
    updateVolume,
  };

  return (
    <PlayerContext.Provider value={value}>
      {children}
    </PlayerContext.Provider>
  );
};
