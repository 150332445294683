import React from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import storage from '../../../services/storage';

const DeleteAlbumButton = ({ album }) => {
  const handleDelete = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (window.confirm(`Are you sure you want to delete "${album.name}"?`)) {
      try {
        await storage.deleteAlbum(album);
      } catch (error) {
        console.error('Error deleting album:', error);
      }
    }
  };

  return (
    <button
      onClick={handleDelete}
      className="w-full flex items-center space-x-2 px-4 py-2 text-sm text-red-600 hover:bg-red-50 dark:text-red-400 dark:hover:bg-gray-700"
    >
      <TrashIcon className="h-4 w-4" />
      <span>Delete</span>
    </button>
  );
};

export default DeleteAlbumButton;