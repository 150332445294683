// AudioPlayerService.jsx
// services/AudioPlayerService.js
class AudioPlayerService {
  constructor() {
    this.audio = new Audio();
    this.cache = new Map();
  }

  load(src) {
    if (this.cache.has(src)) {
      this.audio.src = this.cache.get(src);
    } else {
      this.cache.set(src, src);
      this.audio.src = src;
    }
    
    // Add error handling for audio playback
    this.audio.onerror = (e) => {
      console.error('Audio playback error:', e);
    };

    this.audio.play().catch(e => {
      console.error('Playback failed:', e);
    });
  }

  play() {
    this.audio.play().catch(e => {
      console.error('Playback failed:', e);
    });
  }

  pause() {
    this.audio.pause();
  }

  togglePlay() {
    if (this.audio.paused) {
      this.play();
    } else {
      this.pause();
    }
  }
  
  getAudioElement() {
    return this.audio;
  }
  
  setCurrentTime(time) {
    this.audio.currentTime = time;
  }

  on(event, callback) {
    this.audio.addEventListener(event, callback);
  }

  off(event, callback) {
    this.audio.removeEventListener(event, callback);
  }
}

export default new AudioPlayerService();
