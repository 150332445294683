import { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const useDraftValidationObserver = (album) => {
  const [validationState, setValidationState] = useState({
    hasCover: false,
    hasTracks: false,
    hasValidTracks: false,
    hasAlbumName: false,
    isLoading: true,
  });

  const [isValid, setIsValid] = useState(false);
  const [validationMessage, setValidationMessage] = useState(null);

  useEffect(() => {
    if (!album?.docId) {
      // No album or docId, no Firestore subscription
      setValidationState((prev) => ({ ...prev, isLoading: false }));
      setIsValid(false);
      setValidationMessage("No album to validate");
      return;
    }

    const user = firebase.auth().currentUser;
    if (!user) {
      console.error('No user logged in.');
      setValidationState((prev) => ({ ...prev, isLoading: false }));
      setIsValid(false);
      setValidationMessage("User not logged in");
      return;
    }

    const albumRef = firebase.firestore()
      .collection('studio')
      .doc(user.uid)
      .collection('drafts')
      .doc(album.docId);

    const tracksRef = albumRef.collection('tracks');
    const imagesRef = albumRef.collection('images');

    let albumData = null;
    let tracksData = [];
    let imagesData = [];

    const updateValidation = () => {
      if (!albumData) {
        // Album data not loaded yet, keep loading
        setValidationState(prev => ({ ...prev, isLoading: true }));
        return;
      }

      console.log('Validation - Album Data:', albumData);
      console.log('Validation - Images Data:', imagesData);

      // Check for cover art in both the main document and images subcollection
      const hasCoverInMainDoc = Boolean(albumData.cover_art_url);
      const hasCoverInImages = Boolean(imagesData.some(img => img.public_url));
      const hasCover = hasCoverInMainDoc || hasCoverInImages;

      console.log('Cover Validation:', {
        hasCoverInMainDoc,
        hasCoverInImages,
        hasCover,
        cover_art_url: albumData.cover_art_url,
        imagesData: imagesData.map(img => ({ id: img.id, url: img.public_url }))
      });

      const hasAlbumName = Boolean(albumData.name && albumData.name.trim());
      const hasTracks = tracksData.length > 0;
      const hasValidTracks = hasTracks && tracksData.every(
        track => track && track.track_name && track.track_name.trim()
      );

      const isLoading = false;
      const newValidationState = {
        hasCover,
        hasAlbumName,
        hasTracks,
        hasValidTracks,
        isLoading,
      };

      console.log('Final Validation State:', newValidationState);

      setValidationState(newValidationState);

      // Determine validity and message
      const newIsValid = hasCover && hasAlbumName && hasTracks && hasValidTracks;
      setIsValid(newIsValid);

      let message = '';
      if (!hasAlbumName) message += 'Album name is required. ';
      if (!hasCover) message += 'Cover art is required. ';
      if (!hasTracks) message += 'At least one track is required. ';
      else if (!hasValidTracks) message += 'All tracks must have valid names. ';

      setValidationMessage(message || 'Album is valid');
    };

    // Subscribe to album document changes
    const albumUnsubscribe = albumRef.onSnapshot(doc => {
      albumData = doc.data();
      updateValidation();
    }, error => {
      console.error('Album snapshot error:', error);
      setValidationState(prev => ({ ...prev, isLoading: false }));
      setIsValid(false);
      setValidationMessage("Error loading album");
    });

    // Subscribe to tracks collection changes
    const tracksUnsubscribe = tracksRef.onSnapshot(snapshot => {
      tracksData = [];
      snapshot.forEach(doc => {
        const trackData = doc.data();
        tracksData.push({ id: doc.id, ...trackData });
      });
      updateValidation();
    }, error => {
      console.error('Tracks snapshot error:', error);
      setValidationState(prev => ({ ...prev, isLoading: false }));
      setIsValid(false);
      setValidationMessage("Error loading tracks");
    });

    // Subscribe to images collection changes
    const imagesUnsubscribe = imagesRef.onSnapshot(snapshot => {
      imagesData = [];
      snapshot.forEach(doc => {
        const imageData = doc.data();
        imagesData.push({ id: doc.id, ...imageData });
      });
      updateValidation();
    }, error => {
      console.error('Images snapshot error:', error);
      setValidationState(prev => ({ ...prev, isLoading: false }));
      setIsValid(false);
      setValidationMessage("Error loading images");
    });

    // Cleanup subscriptions
    return () => {
      albumUnsubscribe();
      tracksUnsubscribe();
      imagesUnsubscribe();
    };
  }, [album?.docId]);

  return {
    isValid,
    validationState,
    isLoading: validationState.isLoading,
    validationMessage
  };
};

export default useDraftValidationObserver;