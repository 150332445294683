import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import LightSpinner from '../../loading/LightSpinner';
import { usePlayer } from '../../../contexts/PlayerContext';
import { PauseIcon, PlayIcon } from '@heroicons/react/24/solid';

const formatDuration = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${mins}:${secs.toString().padStart(2, '0')}`;
};

const SharedDraftView = () => {
  const { docId } = useParams();
  const { play, pause, currentTrack } = usePlayer();
  const [draft, setDraft] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDraft = async () => {
      try {
        // Query all users' drafts to find the shared draft
        const db = firebase.firestore();
        const studioRef = db.collection('studio');
        const usersSnapshot = await studioRef.get();

        let draftDoc = null;
        let userId = null;
        // Search through each user's drafts
        for (const userDoc of usersSnapshot.docs) {
          const draftRef = userDoc.ref.collection('drafts').doc(docId);
          const draftSnap = await draftRef.get();
          
          if (draftSnap.exists && draftSnap.data().isShared) {
            draftDoc = draftSnap;
            userId = userDoc.id;
            break;
          }
        }

        if (!draftDoc) {
          setError('This shared draft link is invalid or has expired.');
          setLoading(false);
          return;
        }

        // Get the latest album artwork
        const imagesRef = draftDoc.ref.collection('images')
          .orderBy('last_updated', 'desc')
          .limit(1);
        const imagesSnap = await imagesRef.get();
        const artworkUrl = !imagesSnap.empty ? imagesSnap.docs[0].data().public_url : null;

        // Get all tracks
        const tracksRef = draftDoc.ref.collection('tracks').orderBy('index', 'asc');
        const tracksSnap = await tracksRef.get();
        const tracks = tracksSnap.docs.map(doc => {
          const data = doc.data();
          // The compressed file URL should be the full Firebase URL
          const compressedUrl = data.compressed_url || null;  
          const losslessUrl = data.url;
          const isPlaceholder = !compressedUrl && !losslessUrl;
          
          return {
            id: doc.id,
            ...data,
            name: data.track_name,
            compressedUrl,
            losslessUrl,
            // Store the file type for format checking
            fileType: losslessUrl ? losslessUrl.split('.').pop().split('?')[0].toLowerCase() : null,
            isPlaceholder
          };
        });

        setDraft({
          id: draftDoc.id,
          ...draftDoc.data(),
          artwork: artworkUrl,
          tracks: tracks
        });
        setLoading(false);
      } catch (err) {
        console.error('Error fetching shared draft:', err);
        setError('Failed to load the shared draft. Please try again later.');
        setLoading(false);
      }
    };

    fetchDraft();
  }, [docId]);

  const handlePlayTrack = (track) => {
    if (track.isPlaceholder) return;

    // Always use lossless URL
    let audioUrl = track.losslessUrl;
    
    if (!audioUrl) {
      console.error('No playable audio URL available for track:', track.name);
      return;
    }
    
    // Prepare all tracks with their lossless URLs
    const playableTracks = draft.tracks.map(t => ({
      ...t,
      file: t.losslessUrl
    }));
    
    if (currentTrack?.id === track.id) {
      pause();
    } else {
      play({ ...track, file: audioUrl }, playableTracks);
    }
  };

  if (loading) {
    return <LightSpinner />;
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="max-w-md p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
          <h2 className="text-xl font-semibold text-red-600 dark:text-red-400 mb-4">Error</h2>
          <p className="text-gray-700 dark:text-gray-300">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 dark:bg-gray-900">
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center justify-between mb-4">
          <div className="flex-1">
            <h1 className="text-2xl font-bold">{draft.name}</h1>
            <p className="text-sm text-gray-600">
              By {draft.artist || 'Unknown Artist'}
            </p>
          </div>
          {/* Show lossless badge if there are tracks with lossless versions */}
          {draft.tracks?.some(track => track.losslessUrl) && (
            <div
              className="px-3 py-1 rounded text-sm bg-indigo-600 text-white cursor-default"
            >
              Lossless
            </div>
          )}
        </div>
        <div className="flex flex-col md:flex-row gap-8">
          {/* Album Artwork */}
          <div className="w-full md:w-1/3">
            <div className="aspect-square bg-gray-200 dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
              {draft.artwork ? (
                <img 
                  src={draft.artwork} 
                  alt={draft.name} 
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center">
                  <svg className="w-24 h-24 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3" />
                  </svg>
                </div>
              )}
            </div>
          </div>

          {/* Album Info */}
          <div className="flex-1">
            <div className="text-gray-600 dark:text-gray-400 mb-6">
              <p>Shared {new Date(draft.sharedAt?.seconds * 1000).toLocaleDateString()}</p>
            </div>

            {/* Tracks List */}
            <div className="space-y-2">
              {draft.tracks?.map((track, index) => (
                <div 
                  key={track.id}
                  className={`flex items-center gap-4 p-4 hover:bg-gray-50 rounded-lg ${track.isPlaceholder ? 'opacity-50' : ''}`}
                >
                  {track.isPlaceholder ? (
                    <div className="p-2 rounded-full bg-gray-100">
                      <span className="text-xs text-gray-500">Placeholder</span>
                    </div>
                  ) : (
                    <button 
                      onClick={() => handlePlayTrack(track)}
                      className="p-2 rounded-full hover:bg-gray-200 transition-colors"
                      disabled={track.isPlaceholder}
                    >
                      {currentTrack?.id === track.id ? (
                        <PauseIcon className="w-5 h-5" />
                      ) : (
                        <PlayIcon className="w-5 h-5" />
                      )}
                    </button>
                  )}
                  <div className="flex-1">
                    <div className={`font-medium ${track.isPlaceholder ? 'text-gray-400' : ''}`}>
                      {track.name || `Track ${index + 1}`}
                    </div>
                    {!track.isPlaceholder && track.duration && (
                      <div className="text-sm text-gray-500">
                        {formatDuration(track.duration)}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* Additional Metadata */}
            {draft.metadata && (
              <div className="mt-8 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg">
                <h2 className="text-lg font-semibold mb-2 dark:text-white">Additional Information</h2>
                <dl className="grid grid-cols-2 gap-2 text-sm">
                  {Object.entries(draft.metadata).map(([key, value]) => (
                    <React.Fragment key={key}>
                      <dt className="text-gray-600 dark:text-gray-400">{key}</dt>
                      <dd className="text-gray-900 dark:text-gray-300">{value}</dd>
                    </React.Fragment>
                  ))}
                </dl>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharedDraftView;
