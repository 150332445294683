import React, { useRef, useEffect } from 'react';
import { usePlayer } from '../../contexts/PlayerContext';
import { 
  PlayIcon, 
  PauseIcon, 
  ForwardIcon, 
  BackwardIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon
} from '@heroicons/react/24/solid';

const formatTime = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${mins}:${secs.toString().padStart(2, '0')}`;
};

const GlobalPlayer = () => {
  const audioRef = useRef(null);
  const {
    currentTrack,
    isPlaying,
    volume,
    progress,
    duration,
    play,
    pause,
    next,
    previous,
    updateProgress,
    updateDuration,
    updateVolume,
    isLossless,
  } = usePlayer();

  useEffect(() => {
    if (!audioRef.current || !currentTrack) return;

    // Determine which URL to use
    const audioUrl = isLossless ? currentTrack.losslessUrl : currentTrack.compressedUrl;
    
    // If compressed URL isn't available yet, fall back to lossless
    const finalUrl = !isLossless && !audioUrl ? currentTrack.losslessUrl : audioUrl;
    
    if (!finalUrl) {
      console.error('No playable audio URL available for track:', currentTrack.name);
      return;
    }

    // Clear any existing error handlers
    audioRef.current.onerror = null;
    
    // Set up error handling
    audioRef.current.onerror = (e) => {
      console.error('Audio error:', e);
      console.error('Failed to load audio:', finalUrl);
      
      // If this is an AIF file and we're trying to play lossless, suggest using compressed
      if (finalUrl.toLowerCase().includes('.aif')) {
        console.error('AIF files are not supported in the browser. Please wait for the compressed version to be available.');
      }
    };
    
    // Add source element with type for better format support
    audioRef.current.innerHTML = ''; // Clear existing sources
    const source = document.createElement('source');
    source.src = finalUrl;
    
    // Set the correct MIME type based on file extension
    const fileExtension = finalUrl.split('.').pop().split('?')[0].toLowerCase();
    switch (fileExtension) {
      case 'mp3':
        source.type = 'audio/mpeg';
        break;
      case 'wav':
        source.type = 'audio/wav';
        break;
      case 'aif':
      case 'aiff':
        source.type = 'audio/x-aiff';
        break;
      default:
        source.type = 'audio/mpeg'; // Default to MP3
    }
    
    audioRef.current.appendChild(source);
    audioRef.current.load(); // Reload the audio element
    
    if (isPlaying) {
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        playPromise.catch(error => {
          console.error('Playback error:', error);
          if (finalUrl.toLowerCase().includes('.aif')) {
            console.error('This browser cannot play AIFF files. The compressed version will be available soon.');
          }
        });
      }
    }
  }, [currentTrack, isPlaying, isLossless, play]);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying, currentTrack]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      updateProgress(audioRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      updateDuration(audioRef.current.duration);
    }
  };

  const handleSeek = (e) => {
    const seekTime = e.target.value;
    if (audioRef.current) {
      audioRef.current.currentTime = seekTime;
      updateProgress(seekTime);
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    updateVolume(newVolume);
  };

  if (!currentTrack) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-900 text-white p-4 shadow-lg">
      <div className="max-w-7xl mx-auto flex items-center gap-4">
        {/* Track Info */}
        <div className="flex items-center justify-between w-full px-4 py-2 bg-gray-800">
          <div className="flex-1 min-w-0 mr-6">
            <div className="text-white truncate">{currentTrack?.name || 'No track selected'}</div>
            <div className="text-gray-400 text-sm flex items-center space-x-2">
              <span className="truncate">{currentTrack?.artist || 'Unknown artist'}</span>
              {currentTrack && (
                <span className="px-2 py-0.5 rounded text-xs bg-indigo-600 text-white">
                  Lossless
                </span>
              )}
            </div>
          </div>
        </div>

        {/* Player Controls */}
        <div className="flex flex-col items-center flex-1">
          <div className="flex items-center gap-4 mb-2">
            <button 
              onClick={previous}
              className="p-2 hover:text-green-400 transition-colors"
            >
              <BackwardIcon className="w-5 h-5" />
            </button>
            <button 
              onClick={isPlaying ? pause : () => play(currentTrack)}
              className="p-3 bg-green-500 rounded-full hover:bg-green-400 transition-colors"
            >
              {isPlaying ? (
                <PauseIcon className="w-6 h-6" />
              ) : (
                <PlayIcon className="w-6 h-6" />
              )}
            </button>
            <button 
              onClick={() => {
                if (audioRef.current) {
                  audioRef.current.currentTime = 0;
                }
                next();
              }}
              className="p-2 hover:text-green-400 transition-colors"
            >
              <ForwardIcon className="w-5 h-5" />
            </button>
          </div>

          <div className="flex items-center gap-2 w-full">
            <span className="text-sm">{formatTime(progress)}</span>
            <input
              type="range"
              min="0"
              max={duration || 0}
              value={progress}
              onChange={handleSeek}
              className="flex-1 h-1 bg-gray-600 rounded-lg appearance-none cursor-pointer"
            />
            <span className="text-sm">{formatTime(duration)}</span>
          </div>
        </div>

        {/* Volume Control */}
        <div className="flex items-center gap-2 w-1/4 justify-end">
          <button 
            onClick={() => updateVolume(volume === 0 ? 1 : 0)}
            className="p-2 hover:text-green-400 transition-colors"
          >
            {volume === 0 ? (
              <SpeakerXMarkIcon className="w-5 h-5" />
            ) : (
              <SpeakerWaveIcon className="w-5 h-5" />
            )}
          </button>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
            className="w-24 h-1 bg-gray-600 rounded-lg appearance-none cursor-pointer"
          />
        </div>

        <audio
          ref={audioRef}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
          onEnded={next}
        />
      </div>
    </div>
  );
};

export default GlobalPlayer;
