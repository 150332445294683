import React from 'react';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import DeleteAlbumButton from './DeleteAlbumButton';

export default function AlbumSettingsView({ album, isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#0E1117] rounded-lg p-6 max-w-md w-full mx-4 shadow-xl">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-2">
            <Cog6ToothIcon className="h-5 w-5 text-green-400" />
            <h2 className="text-xl font-bold text-green-400">Album Settings</h2>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            ×
          </button>
        </div>

        <div className="space-y-6">
          <div className="pb-4 border-b border-gray-800">
            <h3 className="text-white text-sm font-medium mb-2">Album Name</h3>
            <p className="text-gray-400">{album.name}</p>
          </div>

          <div className="pb-4 border-b border-gray-800">
            <h3 className="text-white text-sm font-medium mb-2">Danger Zone</h3>
            <div className="bg-red-900/20 rounded-lg p-4">
              <p className="text-sm text-gray-400 mb-4">
                Once you delete an album, there is no going back. Please be certain.
              </p>
              <DeleteAlbumButton album={album} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
