import React, { useState, useContext, useCallback } from "react";
import { Draggable } from "react-beautiful-dnd";
import { PlayIcon, PauseIcon } from "@heroicons/react/24/solid";
import { TrashIcon } from '@heroicons/react/24/outline';
import drafting from '../../../services/drafting';
import TaskActions from "./TaskActions";
import TrackNameEditor from "./TrackNameEditor";
import useTrackEditor from "./useTrackEditor";
import TrackArtistEditor from "./TrackArtistEditor";
import AudioPlayerContext from "../../file_mgmt/drive/AudioPlayerContext";
import InferTrackNameButton from "./InferTrackNameButton.jsx";

const TrackObjectCellContent = ({
  task,
  isEditing,
  startEditing,
  saveTrackName,
  cancelEditing,
  inferredTrackName,
}) =>
  isEditing ? (
    <TrackNameEditor
      trackName={inferredTrackName || task.trackName}
      onSave={saveTrackName}
      onCancel={cancelEditing}
    />
  ) : (
    <div className="cursor-pointer" onClick={startEditing}>
      <p className="font-theFutureBold text-gray-100 text-lg">
        {inferredTrackName || task.trackName}
      </p>
      <p className="text-gray-400 text-sm py-0.5">{task.content}</p>
    </div>
  );

const TrackObjectCell = ({
  task,
  index,
  album,
  column_id,
  styleVariant = "default",
  data,
  setData
}) => {
  const { isEditing, startEditing, cancelEditing, saveTrackName } = useTrackEditor(task, album, index);
  const [editMode, setEditMode] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { playAudio, pauseAudio, playing, src } = useContext(AudioPlayerContext);

  const isPlaying = playing && src.audioSrc === task.url;

  const handlePlayPause = (event) => {
    event.stopPropagation();
    isPlaying
      ? pauseAudio()
      : playAudio({
          title: task.trackName,
          artist: task.artistName || "Unknown Artist",
          audioSrc: task.url,
        });
  };

  const handleDelete = useCallback(async () => {
    if (!album || !task.id) return;
    
    setIsDeleting(true);
    try {
      // Delete track from Firestore
      await drafting.manageTrack(album, task, "delete");

      // Update local state
      const firstColumnId = data.columnOrder[1];
      const column = data.columns[firstColumnId];
      
      // Remove track ID from column's taskIds
      const newTaskIds = column.taskIds.filter(id => id !== task.id);
      const updatedColumn = {
        ...column,
        taskIds: newTaskIds
      };

      // Remove track from tasks and update column
      const newTasks = { ...data.tasks };
      delete newTasks[task.id];

      const newData = {
        ...data,
        tasks: newTasks,
        columns: {
          ...data.columns,
          [firstColumnId]: updatedColumn
        }
      };

      setData(newData);
    } catch (error) {
      console.error('Error deleting track:', error);
    } finally {
      setIsDeleting(false);
      setEditMode(false);
    }
  }, [album, task, data, setData]);

  const draggableCellStyle = `
    bg-gray-800 
    border border-gray-700
    rounded-lg p-4 mb-3
    shadow transition-colors duration-200 ease-in-out
    w-full min-w-0
    ${styleVariant === "alternate" ? "hover:bg-gray-700/50" : "hover:bg-gray-700/50"}
  `;

  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={`${draggableCellStyle} ${
            snapshot.isDragging ? "border-blue-500" : "border-gray-700"
          }`}
        >
          {/* Top Section: Track Name and Player Controls */}
          <div className="flex items-start justify-between mb-3 space-x-4">
            <div className="flex-grow min-w-0">
              <TrackObjectCellContent
                task={task}
                isEditing={isEditing}
                startEditing={startEditing}
                saveTrackName={saveTrackName}
                cancelEditing={cancelEditing}
                inferredTrackName={task.inferredTrackName}
              />
            </div>

            {task.url && (
              <button
                onClick={handlePlayPause}
                className="bg-blue-600 hover:bg-blue-500 text-white rounded-full p-2 flex-shrink-0"
              >
                {isPlaying ? (
                  <PauseIcon className="h-5 w-5" />
                ) : (
                  <PlayIcon className="h-5 w-5" />
                )}
              </button>
            )}
          </div>

          {/* Middle Section: Artist Editor and Infer Name */}
          <div className="flex items-center justify-between mt-2">
            <TrackArtistEditor track={task} album={album} />
            <InferTrackNameButton task={task} album={album} index={index} />
          </div>

          {/* Bottom Section: Edit Mode Controls */}
          <div className="mt-4 flex items-center space-x-2">
            <button
              onClick={() => setEditMode(!editMode)}
              className="px-3 py-1 text-sm rounded-md border border-gray-700 bg-gray-700/50 text-gray-300 hover:bg-gray-700 cursor-pointer"
            >
              {editMode ? "Done" : "Edit"}
            </button>

            {editMode && (
              <div className="flex-grow border border-gray-700 bg-gray-800 rounded-lg p-3">
                <TaskActions
                  onRename={startEditing}
                  onDelete={handleDelete}
                  track={task}
                  album={album}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default React.memo(TrackObjectCell);