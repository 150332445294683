import { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/compat/app";

export const useFetchWithAuth = (url, options = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [user, userLoading, userError] = useAuthState(firebase.auth());

  useEffect(() => {
    // Reset `fetched` state if URL changes or user signs in/out
    setFetched(false);
  }, [url, user]);

  useEffect(() => {
    const fetchData = async () => {
      if (userLoading || userError || !user || fetched) {
        return; // Exit early if already fetched or user is not ready
      }

      setLoading(true);
      try {
        const idToken = await user.getIdToken(true);
        const response = await fetch(url, {
          ...options,
          method: options.method || "GET",
          headers: {
            ...options.headers,
            "Content-Type": "application/json",
            Authorization: "JWT " + idToken,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        setData(responseData);
        setFetched(true); // Mark as fetched to prevent refetching
      } catch (e) {
        console.error("Error in useFetchWithAuth:", e);
        if (e.message.includes('401') || 
            e.message.includes('403') || 
            e.message.toLowerCase().includes('unauthorized')) {
          // Clear local storage and redirect to login
          localStorage.clear();
          window.location.href = '/login';
          return;
        }
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    // Trigger fetch if `fetched` is false
    if (!fetched) {
      fetchData();
    }
  }, [url, user, userLoading, userError, fetched, options]);

  return { data, loading: loading || userLoading, error: error || userError };
};

export default useFetchWithAuth;
