import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import AddArtistComponent from '../../events/profiles/AddArtistComponent';


const TrackArtistCreator = () => {
  const [showAddArtist, setShowAddArtist] = useState(false);

  const toggleAddArtist = () => {
    setShowAddArtist(!showAddArtist);
  };

  return (
    <div className="p-4 font-sans">
      <button
        onClick={toggleAddArtist}
        className="bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 border-none cursor-pointer p-2 rounded-md transition duration-300 ease-in-out hover:bg-gray-300 dark:hover:bg-gray-600"
        aria-label={showAddArtist ? 'Hide Add Artist' : 'Show Add Artist'}
      >
        <FontAwesomeIcon
          icon={showAddArtist ? faTimes : faPlus}
          className="mr-2"
        />
        {showAddArtist ? 'Hide' : 'Add Artist'}
      </button>

      {showAddArtist && <AddArtistComponent />}
    </div>
  );
};

export default TrackArtistCreator;
