import React, { useState, useEffect } from "react";
import Spinner from "../../loading/Spinner";
import storage from "../../../services/storage";
import { PencilIcon, CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

export default function EditAlbumMetadataView({ album }) {
  const [isEditingAlbumName, setEditingAlbumName] = useState(false);
  const [albumName, setAlbumName] = useState(album?.name || "");
  const [isSaving, setIsSaving] = useState(false);

  // Update albumName when album prop changes
  useEffect(() => {
    if (album?.name) {
      setAlbumName(album.name);
    }
  }, [album?.name]);

  const saveAlbumName = async () => {
    if (!albumName.trim()) return;
    
    setIsSaving(true);
    try {
      await storage.setAlbumName(albumName, album);
      setEditingAlbumName(false);
    } catch (error) {
      console.error("Error saving album name:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      saveAlbumName();
    } else if (e.key === 'Escape') {
      setEditingAlbumName(false);
      setAlbumName(album?.name || "");  // Reset to original name on cancel
    }
  };

  return (
    <div className="flex items-center">
      {!isEditingAlbumName ? (
        <div 
          className="flex items-center group cursor-pointer" 
          onClick={() => setEditingAlbumName(true)}
        >
          <h1 className="text-2xl font-bold text-gray-200 mr-2 group-hover:text-gray-400 transition-colors duration-200">
            {album?.name || <span className="text-gray-500 italic">Click to add album name</span>}
          </h1>
          <PencilIcon className="h-5 w-5 text-gray-500 group-hover:text-gray-400 transition-colors duration-200" />
        </div>
      ) : (
        <div className="flex items-center space-x-2">
          <input
            type="text"
            value={albumName}
            onChange={(e) => setAlbumName(e.target.value)}
            onKeyDown={handleKeyPress}
            className="bg-gray-800 text-gray-200 text-2xl font-bold px-2 py-1 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter album name"
            autoFocus
          />
          {isSaving ? (
            <Spinner className="h-5 w-5" />
          ) : (
            <>
              <button
                onClick={saveAlbumName}
                className="p-1 hover:bg-gray-700 rounded-full transition-colors duration-200"
              >
                <CheckIcon className="h-5 w-5 text-green-500" />
              </button>
              <button
                onClick={() => {
                  setEditingAlbumName(false);
                  setAlbumName(album?.name || "");  // Reset to original name on cancel
                }}
                className="p-1 hover:bg-gray-700 rounded-full transition-colors duration-200"
              >
                <XMarkIcon className="h-5 w-5 text-red-500" />
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
}
